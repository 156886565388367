import React, { Component, useEffect, useState } from "react";

import moment from "moment";
import Header from "../TopHeader";
import Abc from "../footer";
import { useNavigate, useParams } from "react-router-dom";
import loginAction from "../../actions/login.action";
import { openModal, closeModal, extractNameValue, withAppUrl, ShowImage } from "../../utils/helpers"
import ContactModel from "../contact.model/contact.modal";
import ShareTalentForm from "../contact.model/ShareTalent.modal";


const People = (props) => {
   const [user, setUser] = useState('')
   const navigate = useNavigate()
   const [users, setUsers] = useState([])
   const { id } = useParams()

   const [resourceName, setResourceName] = useState('');
   const [isLoading, setIsLoading] = useState(true);

   const openContactModal = (e, userObj) => {
      openModal("contactModal");
      setUser(userObj);
   }



   useEffect(() => {
      if (id) {
         setIsLoading(true);
         loginAction.userbyresource(id, (err, res) => {
            setIsLoading(false);
            if (err) {

            } else {
               setUsers(res.data.people);
               setResourceName(res.data?.resource?.name);
            }
         });
      }
   }, [])

   const handleClick = (e) => {
      // navigate(`/peopledetailportfolio/${e}`)
      navigate(`/peopledetail/${e}`)
   }

   return (
      <>

         <Header></Header>

         <ContactModel user={user} />

         {/* <ShareTalentForm></ShareTalentForm> */}

         <section className="space-wapper down-space people-block">
            <div className="container">
               <h4 className="title">{resourceName}</h4>

               {!isLoading ? <>

                  {users.length > 0 ? <>

                     {users?.map(item =>
                        <div className="people-bar" >
                           <img className="clickable" onClick={(e) => { handleClick(item._id) }} src={`${ShowImage(item?.imageUrl)}`} alt="img" />
                           <div className="people-user">
                              <ul>
                                 <li>
                                    <a className="clickable" onClick={(e) => { handleClick(item._id) }}> {item.name}</a>
                                    <h6>{item.experience} of experience</h6>
                                 </li>
                                 <li>
                                    <button className="btncontact" onClick={(e) => openContactModal(e, item)} type="button">Contact Now</button>
                                 </li>
                              </ul>
                              <p>{item.bio}</p>
                           </div>

                        </div>
                     )}

                  </> : <>
                     <p>No Results Found</p>
                  </>
                  }

               </> : <>
                  <p className="text-center d-block">Loading <i className="fa fa-spin fa-spinner"></i></p>
               </>
               }

            </div>
         </section>

         <Abc></Abc>
      </>
   );

}

export default People;
