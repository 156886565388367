import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router } from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';
 
import { ToastContainer } from 'react-toastify';


ReactDOM.render(
  <Router>
    <div id="appLoader">
      <div id='appLoaderBox'>
        <img src='/img/loader.gif'/>
      </div>
    </div>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <App />
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
