import React, { Component, useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { openModal, closeModal, extractNameValue } from "./../utils/helpers"

import { PASSIONS, SERVICES } from "../utils/app.constants";
import homeActions from "../actions/homeActions";

import moment from "moment";

const OrganizationComponent = (props) => {
    const history = useNavigate();

    const [organizationForm, setOrganizationForm] = useState({
        name: '',
        address: '',
        service: ''
    });

    const handleOrganizationFormChange = (event) => {
        console.log(event);
        const { name, value } = extractNameValue(event.target);
        organizationForm[name] = value;
        setOrganizationForm({ ...organizationForm });
        console.log(organizationForm);
    }

    const submitOrganizationForm = (event) => {
        event.preventDefault();
        homeActions.sendMailToAdmin({
            emailData: organizationForm,
            mailFor: 'Joining as an Organization˛'
        }, (err, res) => {
            if (err) {
                alert("Failed to send your Request");
            } else {
                openModal("thankyou-popup");
            }
        });
    }      


    return (
        <>
            <form className="joiningForm" onSubmit={submitOrganizationForm} method="post">
                <div className="form-group">
                    <label htmlFor="">Name*</label>
                    <input onChange={handleOrganizationFormChange} value={organizationForm.name || ""} name="name" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                </div>
                <div className="form-group">
                    <label htmlFor="">Address</label>
                    <textarea onChange={handleOrganizationFormChange} value={organizationForm.address || ""} name="address" className="form-control" id="" cols="30" placeholder="" rows="7"></textarea>   
                </div>
                <div className="form-group">
                    <label htmlFor="">Product/Services</label>
                    <select onChange={handleOrganizationFormChange} value={organizationForm.service || ""} name="service" className="" id="">
                        <option value="">--Select--</option>
                        {
                            SERVICES.map((serviceItem, i) => {
                                return (
                                    <>
                                        <option key={i} value={serviceItem.title}>{serviceItem.title}</option>
                                    </>
                                )
                            })
                        }
                        <option value="others">Others</option>
                    </select>
                </div>
                <div className="form-group">
                    <button type="submit" className="blue-text-right">Sign Up</button>
                </div>
            </form>
        </>
    )
}

export default OrganizationComponent;