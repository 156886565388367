import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router,useLocation, Routes, Route, Navigate } from "react-router-dom";

import './App.css';

import Home from './components/Home';
import { useNavigate } from 'react-router-dom'
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import JoinUs from "./components/JoinUs";
import BlogPage from "./components/BlogPage";
import "./cssfile/main.css"
import CommunityHub from "./components/CommunityHub";
import Login from "./components/Loginbar/login";
import Signup from "./components/Loginbar/signup";
import 'react-toastify/dist/ReactToastify.css';
import Profile from "./components/profile/profile";
import People from "./components/Resources/People";
import PeopleDetail from "./components/Resources/PeopleDetail";
import PeopleDetailPortfolio from "./components/Resources/PeopleDetailPortfolio";
import IndividualComponent from "./components/IndividualComponent";
import Event from "./components/Event";
import EventDetails from "./components/EventDetails";


function App() {

  const history = useNavigate();

  useEffect(() => {

  }, []);

  document.body.setAttribute("data-page", window?.location?.pathname);

  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); 

  return (
    <>


      <Routes>
        <Route exact path="/" key="home" element={<Home />} />
        <Route exact path="/connect" key="connect" element={<Contact />} />
        <Route exact path="/JoinUs" key="JoinUs" element={<JoinUs />} />
        <Route exact path="/Blog" key="Blog" element={<Blog />} />
        <Route exact path="/community-hub" key="community-hub" element={<CommunityHub />} />
        <Route exact path="/event" key="Event" element={<Event />} />
        <Route exact path="/blogpage/:id" key="blogPage" element={<BlogPage />} />

        <Route exact path="/people/:id" key="people" element={<People />} />
        <Route exact path="/peopledetail/:id" key="peopledetail" element={<PeopleDetail />} />
        <Route exact path="/event/details/:id" key="EventDetails" element={<EventDetails />} />
        <Route exact path="/peopledetailportfolio/:id" key="peopledetailportfolio" element={<PeopleDetailPortfolio />} />

        <Route exact path="/IndividualComponent" key="IndividualComponent" element={<IndividualComponent />} />


        {localStorage.getItem("token") ?
          <>
            <Route exact path="/profile" key="profile" element={<Profile />} />
          </>
          :
          <>
            <Route exact path="/login" key="login" element={<Login />} />
            <Route exact path="/signup" key="signup" element={<Signup />} />
          </>
        }

        {/* <Route exact path="/*" key="home" element={<Home />} /> */}

      </Routes>

    </>
  );
}

export default App;
