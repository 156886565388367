import React, { Component, useEffect, useState } from "react";
import Header from "../TopHeader"
import Abc from "../footer"
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import { openModal, closeModal, extractNameValue, showToastError, showLoader, hideLoader, showToastSuccess, validateJOIProperty, validateJOIFormField } from "./../../utils/helpers"
import homeActions from "../../actions/homeActions";
import action from "../../actions/login.action";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";

import * as Joi from "joi-browser";
import FieldError from "./../FieldError";

const Signup = () => {
    const navigate = useNavigate()
    const params = useParams();
    const history = useNavigate();
    const [blogList, setBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(6);
    const [pages, setPages] = useState(6);
    const [pageInfo, setpageInfo] = useState({});
    const [resource, setResource] = useState([]);

    const [errors, setErrors] = useState({});

    const [experiences, setExperiences] = useState([
        '1 Year',
        '2 Years',
        '3 Years',
        '4 Years',
        '5 Years',
        '6 Years',
        '7 Years',
        '8 Years',
        '9 Years',
        '10+ Years',
    ]);

    const schema = {
        name: Joi.string().required().label("Name"),
        username: Joi.string().required().label("Email/Phone Number"),
        password: Joi.string().required().label("Password"),
        resource: Joi.string().required().label("Resource"),
        experience: Joi.string().required().label("Experience"),
    };

    const [registerForm, setRegisterForm] = useState({
        name: '',
        username: '',
        password: '',
        resource: '',
        experience: ''
    });

    useEffect(() => {
        action.getResources((err, res) => {
            if (res.statusCode == '200') {
                setResource(res.data);
            } else {
                showToastError(res.message);
            }
        })
    }, [])


    const handleRegisterFormChange = (event) => {
        const { name, value } = extractNameValue(event.target);
        let errorData = { ...errors };
        const errorMessage = validateJOIProperty(schema, event);
        if (errorMessage) {
            errorData[name] = errorMessage;
        } else {
            delete errorData[name];
        }
        registerForm[name] = value;
        setRegisterForm({ ...registerForm });
        setErrors(errorData);
    }

    const submitRegisterForm = (event) => {
        event.preventDefault();
        let errors = validateJOIFormField(registerForm, schema);
        if (errors == null) {
            showLoader();
            action.signUp(registerForm, (err, res) => {
                hideLoader();
                if (res.statusCode == '200') {
                    showToastSuccess(res.message)
                    navigate('/login')

                } else {
                    showToastError(res.message)
                }
            });
        } else {
            setErrors(errors);
        }
    }


    return (
        <>
            <Header></Header>
            <section className="loginbar">
                <div className="containerfluid p-0">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="loginform">
                                <h4>Create an Account</h4>
                                <form onSubmit={submitRegisterForm} method="post" className="userform">
                                    <div className="forminput">
                                        <label>Full Name</label>
                                        <input onChange={handleRegisterFormChange} value={registerForm.name || ""} name="name" placeholder="Please Enter Full Name" type="text" className="form-control" />
                                        <FieldError error={errors.name} />
                                    </div>
                                    <div className="forminput">
                                        <label>Email or Phone number</label>
                                        <input onChange={handleRegisterFormChange} value={registerForm.username || ""} name="username" placeholder="Please Enter Email/Phone Number" type="text" className="form-control" />
                                        <FieldError error={errors.username} />
                                    </div>
                                    <div className="forminput">
                                        <label>Password</label>
                                        <input onChange={handleRegisterFormChange} value={registerForm.password || ""} name="password" placeholder="Please Enter Password" type="password" className="form-control" />
                                        <FieldError error={errors.password} />
                                    </div>

                                    <div className="rowCust">
                                        <div className="row">
                                            <div className="col-md-7 col-12">
                                                <div className="forminput">
                                                    <label>Select Resource</label>
                                                    <select className="form-control" onChange={handleRegisterFormChange} value={registerForm.resource || ""} name="resource">
                                                        <option value="">Select Resource</option>
                                                        {resource.map(item => <option value={item._id}>{item.name}</option>)}
                                                    </select>
                                                    <FieldError error={errors.resource} />
                                                </div>
                                            </div>
                                            <div className="col-md-5 col-12">
                                                <div className="forminput">
                                                    <label>Experience</label>
                                                    <select className="form-control" onChange={handleRegisterFormChange} value={registerForm.experience || ""} name="experience">
                                                        <option value="">Select Experience</option>
                                                        {experiences.map(item => <option value={item}>{item}</option>)}
                                                    </select>
                                                    <FieldError error={errors.experience} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formbtn">
                                        <button className="btn-submit" type="submit">Signup</button>
                                    </div>

                                    <div className="formnote">
                                        <p>Already have an Account? <Link to="/login">Login</Link></p>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="formimg">
                                <img src="/img/form-img.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Abc></Abc>
        </>
    );

}

export default Signup;
