export const PASSIONS = [
    {
        title: "Relationships",
        subItems: [
            {
                title: "Family",
            },
            {
                title: "Spouse",
            },
            {
                title: "Children",
            },
            {
                title: "Grandchildren",
            },
            {
                title: "Friends",
            },
            {
                title: "Siblings",
            },
            {
                title: "Co-workers",
            },
            {
                title: "Acquaintances",
            },
            {
                title: "Extended relatives",
            },
            {
                title: "People We Run Across",
            },
        ]
    },

    {
        title: "Health and Wellness",
        subItems: [
            {
                title: "Living in the present",
            },
            {
                title: "Valuing time",
            },
            {
                title: "Creating simplicity",
            },
            {
                title: "Eating healthy",
            },
            {
                title: "Competing in sports",
            },
            {
                title: "Running",
            },
            {
                title: "Hiking",
            },
            {
                title: "Biking",
            },
            {
                title: "Weight training",
            },
            {
                title: "Swimming",
            },
            {
                title: "Recreation",
            },
            {
                title: "Exercise programs",
            },
            {
                title: "Quiet time",
            },
            {
                title: "Optimal sleep",
            },
            {
                title: "Rest",
            },
            {
                title: "Time away",
            },
            {
                title: "Self-care",
            },
            {
                title: "Taking a day off",
            },
            {
                title: "Extended time away",
            },
            {
                title: "Making healthy food",
            },
        ]
    },

    {
        title: "Personal Development and Life",
        subItems: [
            {
                title: "Reading",
            },
            {
                title: "Writing",
            },
            {
                title: "Maximizing potential",
            },
            {
                title: "Learning new things",
            },
            {
                title: "Adding value",
            },
            {
                title: "Taking action",
            },
            {
                title: "Actively listening to podcasts",
            },
            {
                title: "Masterminds",
            },
            {
                title: "Getting out of comfort zone",
            },
            {
                title: "Focusing on the big things",
            },
            {
                title: 'Acquiring wisdom'
            },
            {
                title: 'Improving personal finances'
            },
            {
                title: 'Expanding skills'
            },
            {
                title: 'Learning'
            },
            {
                title: 'Doing things that matter'
            },
            {
                title: 'Being intentional'
            },
            {
                title: 'Journaling'
            },
            {
                title: 'Getting organized'
            },
            {
                title: 'Pushing your own limits'
            },
            {
                title: 'Taking calculated risks'
            },
        ]
    },

    {
        title: "Fun",
        subItems: [
            {
                title: "Travel",
            },
            {
                title: "Hobbies",
            },
            {
                title: "Recreation",
            },
            {
                title: "Weekend away",
            },
            {
                title: "Vacation",
            },
            {
                title: "Night with friends",
            },
            {
                title: "Sabbatical",
            },
            {
                title: "Playing with kids",
            },
            {
                title: "Sporting events",
            },
            {
                title: "Playing with a pet",
            },
            {
                title: 'Daily activities that bring job',
            },
            {
                title: 'Road trip',
            },
            {
                title: 'Going to a park',
            },
        ]
    },

    {
        title: "Service",
        subItems: [
            {
                title: "Volunteering",
            },
            {
                title: "Adding value",
            },
            {
                title: "Helping others",
            },
            {
                title: "Creating abundance",
            },
            {
                title: "Bettering others",
            },
            {
                title: "Brightening up someone’s day",
            },
            {
                title: "Giving a specific compliment",
            },
            {
                title: "Providing honest feedback",
            },
            {
                title: "Donating time and/or money",
            },
            {
                title: "Mentoring",
            },
        ]
    },
    
];

export const SERVICES = [
    {
        title: "Corporate Volunteering"
    },
    {
        title: "Community Leadership"
    },
    {
        title: "Brand Purpose"
    },
    {
        title: "Cause Campaign"
    },
    {
        title: 'Mobile App Development'
    },
    {
        title: 'Web Development'
    },
    {
        title: 'UI/UX Designs'
    },
    {
        title: 'Web Designs'
    },
    {
        title: 'Custom CRM Solutions'
    },
    {
        title: 'ERP Solutions'
    },
    {
        title: 'Program Design'
    },
    {
        title: 'Community Outreach'
    },
    {
        title: 'CSR'
    },
    {
        title: 'Workshops'
    },
    {
        title: 'Content Creation'
    },
    {
        title: 'Video'
    },
    {
        title: 'Corporate | Feature Films'
    },
    {
        title: 'Animation'
    },
    {
        title: 'Documentary'
    },
];