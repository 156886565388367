import React, { Component, useEffect, useState } from "react"
import Header from "../TopHeader"
import Abc from "../footer"
import homeActions from "../../actions/homeActions"

import ContactFormComponent from "../ContactFormComponent"

const Contact = (props) => {
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const sendmail = () => {

    console.log(name, lastName, email, message, "data to name");
    if (!name && !email && !message) {
      alert("fill information");
      return;
    }
    let data = {
      name, lastName, message, email
    }
    homeActions.sendMailToAdmin(data, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        console.log(res)
        alert("mail sendSuccess ")
      }
    })

  }

  return (
    <>
      <Header></Header>

      <section className="space-wapper pb-5 contactblock">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="say-content">
                <span>Contact us</span>
                <h2>
                  Say <span className="diff">Hello</span>
                </h2>
                <p>For any questions, assistance, or feedback</p>
                <p>
                  Email: <b>Ops@infinitecommunities.com</b>{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="info-section">
                <ContactFormComponent></ContactFormComponent>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Abc></Abc>
    </>
  )
}

export default Contact
