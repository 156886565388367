
import { useEffect, useState } from "react"
import Header from "../TopHeader"
import footer from "../footer"
import ShareTalentForm from "../contact.model/ShareTalent.modal"
import ReactPaginate from 'react-paginate';

import Action from "../../actions/login.action"
import { useNavigate } from "react-router-dom"
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { hideLoader, ShowImage, showLoader, showToastError, showToastSuccess, withAppUrl } from "../../utils/helpers"
import Abc from "../footer"


const Profile = () => {
    const navigate = useNavigate()
    const [pages, setPages] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);

    const [name, setName] = useState('')
    const [username, setEmail] = useState('')
    const [resource, setResource] = useState('')
    const [experience, setExperience] = useState('')
    const [bio, setBio] = useState('')
    const [allResource, setAllResource] = useState([])
    let [id, setId] = useState('')
    const [projectName, setProjectName] = useState('')
    const [description, setDescription] = useState('')
    const [file, setFile] = useState(null)
    const [portfolio, setPortfolio] = useState([]);
    const [image, setImage] = useState(null);
    const [viewImage, setViewImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [experiences, setExperiences] = useState([
        '1 Year',
        '2 Years',
        '3 Years',
        '4 Years',
        '5 Years',
        '6 Years',
        '7 Years',
        '8 Years',
        '9 Years',
        '10+ Years',
    ]);

    let [portfolioOfUser, setPortfolioOfUser] = useState({})

    const handlePageClick = (data) => {
        let current = data.selected + 1;
        console.log(current, "currentpage");
        setCurrentPage(current);

    };

    useEffect(() => {
        loadUserPortfolios()
    }, [currentPage])
    useEffect(async () => {
        let _id = (localStorage.getItem("id"));

        id = _id;
        setId(_id);
        console.log('local', _id);

        const resorce = await Action.getResources((err, res) => {
            console.log(res);
            if (res.statusCode == '200') {

                setAllResource(res.data)
                // toast(res.message)
            } else {
                // toast(res.message)
            }
        });

        loadUserDetails();
        loadUserPortfolios();

    }, []);

    const loadUserPortfolios = async () => {
        let payload = {}
        payload['id'] = id
        payload['currentPage'] = currentPage
        payload['limit'] = 3
        console.log('payload', payload);
        const portfolio = await Action.getPortfolio(payload, (err, res) => {
            if (res?.statusCode == '200') {
                let response = res.data
                console.log('response', response);
                setPortfolio(response.docs);
                setPages(response.totalPages)
                // toast(res.message)
            } else {
                // toast(res.message)
            }
        });
    }

    const loadUserDetails = async () => {
        const data = await Action.getUserData((err, res) => {
            if (res?.statusCode == '200') {
                let response = res.data
                setName(response.name);
                setEmail(response.username);
                setExperience(response.experience);
                setResource(response.resource);
                setBio(response?.bio);
                setImage(response?.imageUrl);
                setViewImage(response?.imageUrl);
                console.log(response?.imageUrl)
                // toast(res.message)
            } else {
                // toast(res.message)
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        showLoader();
        var formData = new FormData();
        formData.append('name', name);
        formData.append('username', username);
        formData.append('resource', resource);
        formData.append('bio', bio);
        formData.append('experience', experience);
        formData.append('image', image)
        Action.updateProfile(formData, (err, res) => {
            hideLoader();
            if (res?.statusCode == '200') {
                loadUserDetails();
                showToastSuccess(res.message);
            } else {
                showToastError(res.message);
            }
        });
    }

    const openPortfolioModal = () => {
        console.log("jkbjhb", portfolioOfUser)
        window.jQuery("#portfoloModal").modal("show");
        if (portfolioOfUser._id) {
            setProjectName(portfolioOfUser.projectName);
            setDescription(portfolioOfUser.description);
            setFile(portfolioOfUser.image);
        }
    }

    const closePortfolioModal = () => {
        setPortfolioOfUser({})
        setProjectName("")
        setDescription("")
        setFile(null)
        window.jQuery("#portfoloModal").modal("hide");
    }

    const newPortfolio = (e) => {
        console.log("new record");
        setPortfolioOfUser({});
        setProjectName('');
        setDescription('');
        setFile('');
        openPortfolioModal();
    }

    const sumit = async (e) => {
        e.preventDefault()
        var formData = new FormData();
        formData.append('projectName', projectName);
        formData.append('description', description);
        formData.append('file', file);
        formData.append('user', id);
        // formData.append('image', image)
        showLoader();
        const request = await Action.portfolio(formData, (err, res) => {
            hideLoader();
            if (res?.statusCode == '200') {
                console.log("dcjcnenejkcenjjcnejc", res);
                showToastSuccess("Portfolio has been created");
                closePortfolioModal();
                loadUserPortfolios();
                // toast(res.message)
            }
            closePortfolioModal();
        });
    }

    const handleEditPortfolio = async (e, _id) => {
        e.preventDefault()
        const portfolio = await Action.getPortfolioOfUser(_id, (err, res) => {
            if (res?.statusCode == '200') {
                let response = res.data
                portfolioOfUser = response[0];
                setPortfolioOfUser({ ...response[0] });
                openPortfolioModal();
                // toast(res.message)
            } else {
                // toast(res.message)
            }
        });
    }

    const handlePortfolioChange = async (e) => {
        e.preventDefault()
        var formData = new FormData();
        formData.append('projectName', projectName);
        formData.append('description', description);
        formData.append('file', file);
        showLoader();
        const data = await Action.updatePortfolio(portfolioOfUser?._id, formData, (err, res) => {
            hideLoader();
            if (res?.statusCode == '200') {
                navigate('/profile')
                closePortfolioModal();
                loadUserPortfolios();
                showToastSuccess("Portfolio has been updated");
                // toast(res.message)
            } else {
                // toast(res.message)
            }
        });
    }

    const handleDelete = async (e, _id) => {
        if (window.confirm("Delete the item?")) {
            showLoader();
            const data = await Action.deletePortfolio(_id, (err, res) => {
                hideLoader();
                if (res?.statusCode == '200') {
                    showToastSuccess("Delete successfully");
                    loadUserPortfolios();
                    // toast(res.message)
                } else {
                    // toast(res.message)
                }
            });
        }
    }

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    }

    const clickImageField = (e) => {
        document.getElementById("profileImageField").click();
    }

    const logout = () => {
        localStorage.removeItem("token");
        navigate("/");
    }

    return (<>

        <Header></Header>

        <section className="space-wapper down-space people-block">
            <div className="container">

                <h4 className="title">Edit Profile</h4>

                <div className="profile-image-box">
                    <div className="profile-image" style={{ backgroundImage: `url("${ShowImage(viewImage)}")` }}>
                        <span className="icon" onClick={clickImageField}>
                            <i className="fa fa-edit"></i>
                        </span>
                    </div>
                    <div className="actions">
                        <button type="button" className="blue-outline-btn btn-norm" onClick={logout}>Logout</button>
                        <button type="button" className="blue-text-right btn-norm" onClick={handleSubmit}>Save</button>
                    </div>
                </div>

                <div className="from-section profile-page">
                    <form >

                        <div className="form-group d-none">
                            <input id="profileImageField" type="file" onChange={(e) => handleImage(e)} required />
                        </div>


                        <div className="row">
                            <div className="col-md-6 mob-full ">
                                <div className="form-group">
                                    <label for="">Full Name*</label>
                                    <input name="firstName" type="text" className="form-control" onChange={(e) => setName(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" value={name} />
                                </div>
                            </div>
                            <div className="col-md-6 mob-full ">
                                <div className="form-group">
                                    <label for="">Email/Phone Number*</label>
                                    <input name="email" type="text" className="form-control" id="" onChange={(e) => setEmail(e.target.value)} placeholder="" value={username} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mob-full ">
                                <div className="form-group">
                                    <label for="">Resource*</label>
                                    <select name="resource" type="resource" className="form-control" onChange={(e) => setResource(e.target.value)}>
                                        <option value="">Select Resource</option>
                                        {allResource.map((item) => {
                                            return <>
                                                <option selected={resource == item._id} value={item._id}>{item.name}</option>
                                            </>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 mob-full ">
                                <div className="form-group">
                                    <label for="">Experience*</label>
                                    <select name="resource" type="experience" className="form-control" onChange={(e) => setExperience(e.target.value)}>
                                        <option value="">Select Experience</option>
                                        {experiences.map((item) => {
                                            return <>
                                                <option selected={experience == item} value={item}>{item}</option>
                                            </>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="">Bio*</label>
                                    <textarea rows="5" name="message" className="form-control" id="" onChange={(e) => setBio(e.target.value)} value={bio}>{bio}</textarea>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="title-header">
                    <h4 className="title">Edit Portfolio</h4>
                    <a href={undefined} onClick={(e) => newPortfolio(e)} className="blue-text-right btn-norm">Upload New</a>
                </div>

                <div className="row">

                    {portfolio.map((item) => {
                        return <>
                            <div className="col-lg-4 col-md-6 mob-full ">
                                <div className="aside-head">

                                    <img src={`${withAppUrl(item.image)}`} alt="loading..." />
                                    <ul>
                                        <li><h3>{item.projectName}</h3></li>
                                        <li>
                                            <AiFillEdit type="button" onClick={(e) => handleEditPortfolio(e, item._id)} ></AiFillEdit>
                                            <AiFillDelete type="button" onClick={(e) => handleDelete(e, item._id)} ></AiFillDelete>
                                        </li>
                                    </ul>


                                    <p>{item.description}</p>
                                </div>
                            </div>

                        </>
                    }
                    )}

                </div>

            </div>
        </section>



        <div className="modal fade" id="portfoloModal" tabindex="-1" role="dialog" aria-labelledby="portfoloModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-body modal-extra-body theme-form-section">
                        <div className="col">
                            <div className="form-group">
                                <label for="">Name Of The Project</label>
                                <input name="project" type="text" className="form-control" onChange={(e) => setProjectName(e.target.value)} id="project" aria-describedby="emailHelp" placeholder="" value={projectName} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label for="">Description</label>
                                <textarea rows="5" name="description" type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} placeholder="" value={description}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">

                                <input name="file" type="file" accept="image/*" id="description" onChange={(e) => setFile(e.target.files[0])} placeholder="" />
                            </div>
                        </div>
                        {portfolioOfUser._id ?
                            <button type="button" className="blue-text-right btn-norm" onClick={handlePortfolioChange}>Update</button> :
                            <button type="button" className="blue-text-right btn-norm" onClick={sumit}>Save</button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={100}
                    onPageChange={handlePageClick}
                    containerClassName={
                        "pagination justify-content-center"
                    }
                    forcePage={currentPage - 1}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div>
        </div>


        <Abc />

    </>)
}

export default Profile