import React, { useState, useEffect } from "react";
import blogActions from "../../actions/homeActions";
import Navbar from "../NavBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loadLazyLoaded, withAppUrl } from "../../utils/helpers";

const Header = (props) => {
   const [homeData, setHomeData] = useState({});
   const [isHome, setIsHome] = useState(false);
   const [ShowTopBtn, setShowTopBtn] = useState(false)

   const [pageType, setPageType] = useState('home');

   const history = useNavigate();
   let location = useLocation();
   let pathname = location.pathname;

   let BANNER = {
      "joinUs": {
         video: "/videos/join.mp4",
         thumbnail: "/thumbnails/join.webp"
      },
      "connect": {
         video: "/videos/connet.mp4",
         thumbnail: "/thumbnails/connect.webp"
      },
      "community-hub": {

         
         video: "/videos/bg-video.mp4",
         thumbnail: "/thumbnails/bg-video.webp"
      },
      "blog": {
         video: "/videos/blog.mp4",
         thumbnail: "/thumbnails/blog.webp"
      },
      "home": {
         video: "/videos/bg-video.mp4",
         thumbnail: "/thumbnails/bg-video.webp"
      }
   };

   useEffect(() => {
      let type = location.pathname;
      type = type.substring(1)
      if (!type.length > 0) {
         type = "home"
         setIsHome(true);
      }
      setPageType(type);
      let payload = {
         type
      };
   }, []);

   useEffect(() => {
      window.addEventListener('scroll', () => {
         if (window.scrollY > 400) {
            setShowTopBtn(true);
         } else {
            setShowTopBtn(false);
         }
      });
   }, []);

   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   const isActivePage = (pagePath) => {
      if (window.location.pathname === pagePath) {
         return true;
      }
      return false;
   }

   return (
      <>

         {ShowTopBtn == true ? <div className="topbotton"><button
            type="button"
            className="btn btn-floating btn-lg"
            onClick={(e) => {
               e.preventDefault();
               scrollToTop();
            }}
         >
            <img src="img/up-down.png" alt="" />
         </button></div> : ""}

         <section className="main-banner-wrap" >
         {localStorage.getItem('token') && ( pageType == "community-hub") ? '' : 
            <div className="banner-bg-video" >
                {BANNER[pageType] ?  <video className="lazy" autoPlay muted loop playsInline id="myVideo" preload="none" poster={BANNER[pageType].thumbnail}>
                     <source src={BANNER[pageType].video} type="video/mp4" />
                     Your browser does not support HTML5 video.
                  </video>: "" }
               <div className="about-link"></div>
            </div>}
            <div className="overlay-above-content" onscroll="myFunction()">
               <header>
                  <Navbar></Navbar>
                  <div className="container">
                     <div className="banner-title">
                        {isHome == true ?
                           <>
                              <h1>We are in the business of creating shared value for companies and 
communities.</h1>
                           </> :
                           <>
                              {
                                 isActivePage('/joinUs') ?
                                    <>
                                       <div className="home-page">
                                          <p><span>Home</span> &gt; Join Us</p>
                                          <h4>Join Us</h4>
                                          <p>At Infinite Communities our mission is to help purpose-driven organizations and talented professionals connect, so together you can do the best work possible.</p>
                                       </div>
                                    </> : ''
                              }
                              {
                                 isActivePage('/blog') ?
                                    <>
                                       <div className="home-page">
                                          <p><span>Home</span> &gt; Blogs</p>
                                          <h4>Time Is Of The Essence: How Brands Can Still Help Refugees In Afghanistan</h4>
                                          <p>Our own MJ Deery, Creative Director at Infinite Communities offers resources and examples for brands looking for ways to help refugees in Afghanistan</p>
                                       </div>
                                    </> : ''
                              }
                              {
                                 isActivePage('/connect') ?
                                    <>
                                       <div className="home-page">
                                          <p><span>Home</span> &gt; Connect</p>
                                          <h4>Connect with Us</h4>
                                          <p>We’d love to speak to you about your project or engagement</p>
                                       </div>
                                    </> : ''
                              }

                              {localStorage.getItem('token') ? '' : 
                              <>
                              {
                                 isActivePage('/community-hub') ?
                                    <>
                                       <div className="home-page text-center community-banner">
                                          <h4>Welcome To IC Community Resource Hub</h4>
                                          <p>Support our community talent pool. Groups and individuals who put passion and work ethics first. We share their dream of <br /> a better and more sustainable world and have carefully curated their work</p>
                                          <button className="btn blue-text-right " onClick={(e) => { localStorage.getItem("token") ? history("/profile") : history("/signup") }} >Join Us</button>

                                       </div>
                                    </> : ''
                              }</>}
                           </>
                        }
                     </div>
                  </div>
               </header>
            </div>
         </section>
      </>
   );

}

export default Header;
