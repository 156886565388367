import Agent from "./superAgent";
import config from '../config/configg';
import { ServerError } from '../utils/helpers';
const BACKEND_URL = config.BACKEND_URL;

function login(payload, cb) {
  Agent
    .fire('post', `${BACKEND_URL}/websites/signup`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}


const signUp = (payload, cb) => {
  Agent
    .fire('post', `${BACKEND_URL}/websites/signUp`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const logINN = (payload, cb) => {
  Agent
    .fire('post', `${BACKEND_URL}/websites/logIn`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const getResources = (cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/user/resources`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const getEvents = (payload,cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/websites/getEvents?currentPage=${payload.currentPage}&limit=${payload.limit}`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const getUser = (payload, cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/user/${payload}/user`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const updateProfile = ( payload, cb) => {
  Agent
    .fire('put', `${BACKEND_URL}/user/user`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const portfolio = (payload, cb) => {
  Agent
    .fire('post', `${BACKEND_URL}/user/protfolio`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const getPortfolio = (payload, cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/user/${payload.id}/portfolio?currentPage=${payload.currentPage}&limit=${payload.limit}`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const getEvent = (payload, cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/admin/getEventById/${payload.id}`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const getPortfolioOfUser = (payload, cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/user/${payload}/portfolioOfUser`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const updatePortfolio = (id, payload, cb) => {
  Agent
    .fire('put', `${BACKEND_URL}/user/${id}/portfolio`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const userbyresource = (payload, cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/user/${payload}/userbyresource`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const getSpecificPortfolio = (payload, cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/user/${payload}/specific/portfolio`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}


const getUserData = ( cb) => {
  Agent
    .fire('get', `${BACKEND_URL}/user/user`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

const deletePortfolio = (payload, cb) => {
  Agent
    .fire('delete', `${BACKEND_URL}/user/${payload}/deletePortfolio`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

export default {
  login,
  signUp,
  logINN,
  getResources,
  getUser,
  updateProfile,
  portfolio,
  getPortfolio,
  getPortfolioOfUser,
  updatePortfolio,
  userbyresource,
  getSpecificPortfolio,
  getUserData,
  deletePortfolio,
  getEvents,
  getEvent

}