import React, { Component, useEffect, useState } from "react";

import moment from "moment";
import Header from "../TopHeader";
import Abc from "../footer";
import { useParams } from "react-router-dom";
import loginAction from "../../actions/login.action";
import ContactFormComponent from "../ContactFormComponent"
import { openModal, closeModal, extractNameValue, withAppUrl, ShowImage } from "../../utils/helpers"


const PeopleDetailPortfolio = (props) => {
   const { id } = useParams()
   const [portfolio, setPortfolio] = useState({})
   const [user, setUser] = useState({})
   console.log('id', id);

   const [isLoading, setIsLoading] = useState(true);

   const open = () => {
      openModal("contactModal")
   }

   const close = () => {
      closeModal("contactModal")
   }
   useEffect(() => { 
      setIsLoading(true);
      loginAction.getSpecificPortfolio(id, (err, res) => {
         setIsLoading(false);
         if (err) {

         } else {

            setPortfolio(res.data);

         }
      })
      if (portfolio.user) {
         loginAction.getUser(portfolio.user, (err, res) => {
            if (err) {

            } else {
               console.log('res', res);
               setUser(res.data);

            }
         })

      }
   }, portfolio.user)


   return (
      <>

         <Header></Header>

         <section className="space-wapper down-space people-block PeopleDetailPortfolio-block">
            <div className="container">
               {!isLoading ? <>

                  <h4 className="title">{portfolio?.projectName}</h4>
                  <div className="people-bar PeopleDetailPortfolio-bar">
                     <div className="Portfolio-user">
                        <img src={`${ShowImage(user?.imageUrl)}`} alt="img" />
                        <div className="people-user">
                           <ul>
                              <li>
                                 <a href={undefined}>{user?.name}</a>
                                 <h6>{moment(portfolio.created_at).format('LL')}</h6>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="portfolio-bg-img">
                        <img src={`${withAppUrl(portfolio.image)}`} alt="big-img" />
                     </div>
                     <p className="Description">{portfolio.description} </p>
                  </div>

               </> : <>
                  <p className="text-center d-block">Loading <i className="fa fa-spin fa-spinner"></i></p>
               </>
               }

            </div>
         </section>

         <Abc></Abc>
      </>
   );

}

export default PeopleDetailPortfolio;
