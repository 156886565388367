import React, { Component, useEffect, useState } from "react";
import Header from "../TopHeader"
import Abc from "../footer"
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import { openModal, closeModal, extractNameValue, showToastError, hideLoader, validateJOIFormField, validateJOIProperty, showLoader } from "./../../utils/helpers"
import homeActions from "../../actions/homeActions";
import Action from "../../actions/login.action";
import { toast } from "react-toastify";

import * as Joi from "joi-browser";
import FieldError from "./../FieldError";

const Login = () => {
    const navigate = useNavigate()
    const params = useParams();
    const history = useNavigate();
    const [blogList, setBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(6);
    const [pages, setPages] = useState(6);
    const [pageInfo, setpageInfo] = useState({});

    const [errors, setErrors] = useState({});

    const schema = {
        username: Joi.string().required().label("Email/Phone Number"),
        password: Joi.string().required().label("Password"),
    };

    const [loginForm, setLoginForm] = useState({
        username: '',
        password: ''
    });


    const handleLoginFormChange = (event) => {
        const { name, value } = extractNameValue(event.target);
        let errorData = { ...errors };
        const errorMessage = validateJOIProperty(schema, event);
        if (errorMessage) {
            errorData[name] = errorMessage;
        } else {
            delete errorData[name];
        }
        loginForm[name] = value;
        setLoginForm({ ...loginForm });
        setErrors(errorData);
    }

    const submitLoginForm = (event) => {
        event.preventDefault();
        let errors = validateJOIFormField(loginForm, schema);
        if (errors == null) {
            showLoader();
            Action.logINN(loginForm, (err, res) => {
                hideLoader();
                if (res.statusCode == '200') {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('id', res.data.isExist._id);
                    navigate('/profile');
                } else {
                    showToastError(res.message);
                }
            })
        } else {
            setErrors(errors);
        }
    }

    return (
        <>
            <Header></Header>
            <section className="loginbar">
                <div className="containerfluid p-0">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="loginform">
                                <h4>Welcome</h4>
                                <form onSubmit={submitLoginForm} method="post" className="userform">
                                    <div className="forminput">
                                        <label>Email or Phone number</label>
                                        <input onChange={handleLoginFormChange} value={loginForm.username || ""} name="username" placeholder="" type="text" className="form-control" />
                                        <FieldError error={errors.username} />
                                    </div>
                                    <div className="forminput">
                                        <label>Password</label>
                                        <input onChange={handleLoginFormChange} value={loginForm.password || ""} name="password" placeholder="" type="password" className="form-control" />
                                        <FieldError error={errors.password} />
                                    </div>
                                    {/* <div className="forminput forgot-password">
                                <button className="btn-forget">Forgot Password</button>
                            </div> */}
                                    <div className="formbtn">
                                        <button className="btn-submit" type="submit">Login</button>
                                    </div>
                                    <div className="formnote">
                                        <p>Don't have an Account? <Link to="/signup">Sign Up</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="formimg">
                                <img src="/img/form-img.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Abc></Abc>
        </>
    );

}

export default Login;
