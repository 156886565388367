import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";

const Abc = (props) => {

   const history = useNavigate();

   const goToPage = (page) => {
      history(page);
   }

   // 
   return (
      <>
         <footer>
            <div className="footer-section">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-3 col-md-12">
                        <div className="footer-logo">
                           <Link to="/"> <img
                              src="/img/logo-footer.png" alt="" /></Link>

                        </div>
                     </div>
                     <div className="col-lg-4 col-md-12">
                        <div className="footer-content">
                           <h3>Connect</h3>
                           <p>For any questions, assistance, or feedback</p>
                           <p>email us at <b><a href="mailto:Ops@infinitecommunities.com">Ops@infinitecommunities.com</a></b> or fill-up the form.</p>
                        </div>
                     </div>
                     <div className="col-lg-5 col-md-12">
                        <div className="footer-content">
                           <h3>Other Links</h3>
                           <ul>
                              <li><a href={undefined}>What we do</a></li>
                              <li><a href={undefined}>Our Services</a></li>
                              <li><a href="http://www.infinitecommunities.com/">ABOUT IC. </a></li>
                              <li><a target="blank" href="http://infinitecommunities.org/"
                                 className="">IC.ORG.</a></li>
                              <li><a href={undefined} onClick={(e) => goToPage('/community-hub')}
                                 className="clickable">Community Hub</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="copy-right">
               <p><img src="img/copy.png" alt="" /> Infinite Communities {new Date().getFullYear}. All Rights Reserved</p>
            </div>
         </footer>
         {/* 
// Thankyou popup Modal */}
         <div className="modal fade thankyou-popup" id="thankyou-popup" tabindex="-1" aria-labelledby="thankyou-popupLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                     <h4>Thank you for your interest in collaborating with us!</h4>
                     <p>Our community managers will review your information and get back to you as soon as we can! We look forward to accelerating the power of good with you!</p>
                  </div>
               </div>
            </div>
         </div>

         <div className="modal fade thankyou-popup" id="sayhello-popup" tabindex="-1" aria-labelledby="sayhello-popupLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                     <h4>Thank you for reaching out! </h4>
                     <p>Our community managers will review your message and send a reply as soon as we can</p>
                  </div>
               </div>
            </div>
         </div>
      </>
   );

}

export default Abc;
