import React, { Component, useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { openModal, closeModal, extractNameValue } from "./../utils/helpers"

import homeActions from "../actions/homeActions";
import moment from "moment";

import { PASSIONS, SERVICES } from "../utils/app.constants";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

const IndividualComponent = (props) => {
    const history = useNavigate();

    let [individualForm, setIndividualForm] = useState({
        name: '',
        address: '',
        passion: '',
        subPassion: '',
        service: ''
    });

    let [selectedPassion, setSelectedPassion] = useState(null);

    let [subPassions, setSubPassions] = useState([]);

    const updateModalValues = (name, value) => {
        individualForm[name] = value;
        setIndividualForm({ ...individualForm });
        console.log(individualForm);
    }

    const handleIndividualFormChange = (event) => {
        console.log(event);
        const { name, value } = extractNameValue(event.target);
        updateModalValues(name, value);
    }

    const submitIndividualForm = (event) => {
        event.preventDefault();
        homeActions.sendMailToAdmin({
            emailData: individualForm,
            mailFor: 'Joining as an Individual'
        }, (err, res) => {
            if (err) {
                alert("Failed to send your Request");
            } else {
                openModal("thankyou-popup");
            }
        });
    }

    const handlePassionChange = (event) => {
        selectedPassion = event.target.value;
        setSelectedPassion(selectedPassion);
        updateModalValues('passion', selectedPassion);
        let subPassionItemsData = [];
        for (let passion of PASSIONS) {
            if (passion.title == selectedPassion) {
                subPassionItemsData = passion.subItems; 
            }
        }
        setSubPassions(subPassionItemsData);
    }

    return (
        <>
            <form className="joiningForm" onSubmit={submitIndividualForm} method="post">
                <div className="form-group">
                    <label htmlFor="">Name*</label>
                    <input onChange={handleIndividualFormChange} value={individualForm.name || ""} name="name" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                </div>
                <div className="form-group">
                    <label htmlFor="">Address</label>
                    <input onChange={handleIndividualFormChange} value={individualForm.address || ""} name="address" type="" className="form-control" id="" placeholder="" />
                </div>
                <div className="form-group">
                    <label htmlFor="">Passion/Expertise</label>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <select onChange={handlePassionChange} name="passion" value={individualForm.passion || ""} className="" id="">
                                <option value="">--Select--</option>
                                {
                                    PASSIONS.map((passionItem, i) => {
                                        return (
                                            <>
                                                <option key={i} value={passionItem.title}>{passionItem.title}</option>
                                            </>
                                        )
                                    })
                                }
                                <option value="others">Others</option>
                            </select>
                        </div>
                        <div className="col-md-6 col-12">
                            <select onChange={handleIndividualFormChange} name="subPassion" value={individualForm.subPassion || ""} className="" id="">
                                <option value="">--Select--</option>
                                {
                                    subPassions.map((subPassionItem, i) => {
                                        return (
                                            <>
                                                <option key={i} value={subPassionItem.title}>{subPassionItem.title}</option>
                                            </>
                                        )
                                    })
                                }
                                <option value="others">Others</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="">Product/Services</label>
                    <select onChange={handleIndividualFormChange} value={individualForm.service || ""} name="service" className="" id="">
                        <option value="">--Select--</option>
                        {
                            SERVICES.map((serviceItem, i) => {
                                return (
                                    <>
                                        <option key={i} value={serviceItem.title}>{serviceItem.title}</option>
                                    </>
                                )
                            })
                        }
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="form-group">
                    <button type="submit" className="blue-text-right">Sign Up</button>
                </div>
            </form>
        </>
    )
}

export default IndividualComponent;