import React, { Component, useState, useEffect } from "react"
import Header from "../TopHeader"
import Abc from "../footer"

import IndividualComponent from "../IndividualComponent"
import OrganizationComponent from "../OrganizationComponent"

const FormPost = (props) => {

  return (
   <>

       <Header></Header>
     

      <section className="space-wapper mb-5 formsgroup">
         <div className="container">
         <div className="row">
            <div className="col-lg-6 col-md-6">
               <div className="Joining-text ">
                  <h4>Joining as an <span className="diff">organization</span></h4>
               </div>
               
               <div className="from-section">
               <OrganizationComponent></OrganizationComponent>
               </div>
            </div>
            <div className="col-lg-6 col-md-6">
               <div className="Joining-text ">
                  <h4>Joining as an <span className="diff">Individual</span></h4>
               </div>
               
               <div className="from-section">
               <IndividualComponent></IndividualComponent>
               </div>
            </div>
         </div>
         </div>
      </section>
  
      <Abc></Abc>
   </>
  )
}

export default FormPost

