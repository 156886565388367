import React, { Component, useEffect, useState } from "react";

import moment from "moment";
import Header from "../TopHeader";
import Abc from "../footer";
import { useNavigate, useParams } from "react-router-dom";
import loginAction from "../../actions/login.action";
import { openModal, closeModal, extractNameValue, withAppUrl, ShowImage } from "../../utils/helpers"
import ContactModel from "../contact.model/contact.modal";
import ShareTalentForm from "../contact.model/ShareTalent.modal";
import ReactPaginate from 'react-paginate';


const PeopleDetail = (props) => {
   const [users, setUsers] = useState('')
   const navigate = useNavigate()
   const [pages, setPages] = useState(6);
   const [currentPage, setCurrentPage] = useState(1);

   const { id } = useParams()
   console.log(id, 'dd');
   const [portfolio, setPortfolio] = useState([])
   const [user, setUser] = useState({})

   const [isLoading, setIsLoading] = useState(true);

   const handlePageClick = (data) => {
      let current = data.selected + 1;
      console.log(current, "currentpage");
      setCurrentPage(current);

  };


   const open = () => {
      openModal("contactModal")
   }

   const close = () => {
      closeModal("contactModal")
   }


   useEffect(() => {
      setIsLoading(true);
      console.log('id', id);
      let payload = {}
      payload.id = id
      payload.currentPage = currentPage
      payload.limit = 4
      
      loginAction.getPortfolio(payload, (err, res) => {
         if (err) {
         } else {
            setPortfolio(res.data.docs);
            setPages(res.data.totalPages)
         }
      })

      loginAction.getUser(id, (err, res) => {
         setIsLoading(false);
         if (err) {

         } else {
            setUser(res.data);

         }
      })
   }, [currentPage])

   const openContactModal = (e, id) => {
      setUsers(id)
      openModal("contactModal")
   }

   const handleClick = (e) => {
      // console.log(e,'mkmkk');
      navigate(`/peopledetailportfolio/${e}`)


   }
   return (
      <>

         <Header></Header>

         <ContactModel user={user} />

         <section className="space-wapper down-space people-block peopledetail-block">
            <div className="container">

               {!isLoading ? <>

                  <h4 className="title">Profile</h4>

                  <div className="people-bar peopledetail-bar">
                     <img src={`${ShowImage(user?.imageUrl)}`} alt="img" />
                     <div className="people-user">
                        <ul>
                           <li>
                              <a href={undefined}>{user?.name}</a>
                              <h6>{user?.experience} of experience {user?.resource && <strong className="clickable" onClick={(e) => navigate(`/people/${user?.resource?._id}`)}>{user?.resource?.name}</strong>}</h6>
                           </li>
                           <li>
                              <button className="btncontact" onClick={(e) => openContactModal(e, user._id)} type="button">Contact Now</button>
                           </li>
                        </ul>
                        <p>{user?.bio}</p>
                     </div>
                  </div>
                  <h4 className="title portfoliobar-title">Work Portfolio</h4>
                  
                  <div className="row">
                     {portfolio.map(item =>
                        <div className="col-md-6 col-lg-3">

                           <a href={undefined} className="portfolio-listing clickable" onClick={e => handleClick(item._id)}>
                              <div className="portfolio-img">
                                 <img src={`${withAppUrl(item.image)}`} alt="img" />
                              </div>
                              <div className="portfolio-details">
                                 <h4>{item.projectName}</h4>
                                 <h6>{moment(item.created_at).format('LL')}</h6>
                                 {/* <p>{item.description}</p> */}

                                 {item.description.length > 100 ?
                                    (
                                       <div className="text-black">
                                          {`${item.description.substring(0, 100)}... Read more`}
                                       </div>
                                    ) :
                                    <p>{item.description}</p>
                                 }
                              </div>

                           </a>
                        </div>
                     )}

                  </div>


               </> : <>
                  <p className="text-center d-block">Loading <i className="fa fa-spin fa-spinner"></i></p>
               </>
               }

            </div>
         </section>
         <div className="row">
            <div className="col-12">
                <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={100}
                    onPageChange={handlePageClick}
                    containerClassName={
                        "pagination justify-content-center"
                    }
                    forcePage={currentPage - 1}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div>
        </div>



         <Abc></Abc>
      </>
   );

}

export default PeopleDetail;
