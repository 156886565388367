import React, { Component, useState, useEffect } from "react"
// import homeActions from "../../actions/homeActions";
// import loginActions from "../../actions/login.action";
// import { openModal, closeModal, extractNameValue, withAppUrl } from "./../../utils/helpers"
// import Header from "../TopHeader"
// import Abc from "../footer"

import homeActions from "../actions/homeActions";
import loginActions from "../actions/login.action";
import { withAppUrl, loadLazyLoaded, ShowImage } from "../utils/helpers";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import Header from "./TopHeader";
import Abc from "./footer";
import blogActions from '../actions/homeActions'


const CommunityHub = () => {
    const history = useNavigate();
const [classs, setClass] =useState('sec-whatwedo')

    const [Expert, setExpert] = useState([]);
    const [blogList, setBlog] = useState([]);
    const [ourWorkList, setOurWorkList] = useState([]);
    const [resources, setResources] = useState([])

    const [homeData, setHomeData] = useState({});
    const [isHome, setIsHome] = useState(false);
    const [ShowTopBtn, setShowTopBtn] = useState(false)
    const [isLoading, setIsLoading] = useState(true);


    let location = useLocation();
    let pathname = location.pathname;

    // useEffect(() => {
    //     let type = "home";
    //     type = type.substring(0)
    //     if (!type.length > 0) {
    //         type = "home"
    //         setIsHome(true)
    //     }
    //     let payload = {
    //         type
    //     };
    //     console.log(type)
      
    //     loadLazyLoaded();
    // }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {

            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    const isActivePage = (pagePath) => {
        if (window.location.pathname === pagePath) {
            return true;
        }
        return false;
    }


    useEffect(() => {
        let payload = {};
        homeActions.getExpert((err, res) => {
            if (err) {

            } else {
                setExpert(res.data);

            }
        })
    }, [])
    useEffect(() => {
        let payload = {};
        homeActions.getBlog(payload, (err, res) => {
            if (err) {

            } else {
                setBlog(res.data.blogList);
            }
        })
        homeActions.getOurWork((err, res) => {
            if (err) {

            } else {
                setOurWorkList(res.data);

            }
        })
        setIsLoading(true);
        loginActions.getResources((err, res) => {
            setIsLoading(false);

            if (err) {

            } else {
                setResources(res.data);

            }
        })

    }, []);

    const handleResources = (e) => {
        history(`/people/${e}`)
    }
    useEffect(() => {

    {localStorage.getItem("token") ? 
    setClass('sec-whatwedo afterlogin') :
    setClass('sec-whatwedo')
     }
    },[])



    return (<>

        <Header></Header>
        <section >
            <div className="container">
               {!isLoading ? <>


        <section className={`${classs}`}>
            <div className="container">
                        <div className="section-head work">
                            <span>What We do</span>
                            <h2>Find a <span className="diff">resources</span></h2>
                        </div>
            </div>
            <div className="top-wapper rescorse-block">
                <div className="container">
                    {/* <div className="row" > */}
                    <div className="row" style={{pointerEvents:"none"}}>
                        {resources?.map((item, index) =>

                            <div key={index} className="col-lg-3 col-md-6 col-sm-12" >
                                <a href={undefined} className="resource-box Rsource-bar" onClick={(e) => handleResources(item?._id)}>
                                    <div className="recource-img">
                                        <img src={`${ShowImage(item?.imageUrl)}`} alt="img" />
                                    </div>
                                    <div className="resource-content" >
                                        <h4>{item?.name}</h4>
                                        <p>{item?.description}</p>
                                        {/* {item.description.length > 40 ?
                                            (
                                                <div className="text-black">
                                                    {`${item.description.substring(0, 40)}... Read more`}
                                                </div>
                                            ) :
                                            <p>{item.description}</p>
                                        } */}

                                    </div>
                                </a>
                            </div>)}
                    </div>
                </div>
            </div>
        </section>

        </> : <>
                  <p className="text-center d-block">Loading <i className="fa fa-spin fa-spinner"></i></p>
               </>
               }

            </div>
         </section>

        <Abc></Abc>
    </>)
}

export default CommunityHub;