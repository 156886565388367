import React, { Component, useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { openModal, closeModal, extractNameValue } from "./../utils/helpers"

import homeActions from "../actions/homeActions";

import moment from "moment";

const ContactFormComponent = (props) => {  
    const history = useNavigate();

    const [contactForm, setContactForm] = useState({
        firstName: '',
        lastName: '', 
        email: '',
        message: ''
    });


    const handleContactFormChange = (event) => {
        console.log(event);
        const { name, value } = extractNameValue(event.target);
        contactForm[name] = value;
        setContactForm({ ...contactForm });
        console.log(contactForm);
    }

    const submitContactForm = (event) => {
        event.preventDefault();
        homeActions.sendMailToAdmin({
            emailData: contactForm,
            mailFor: 'Contact Form'
        }, (err, res) => {
            if (err) {
                alert("Failed to send your Request");
            } else {
                openModal("sayhello-popup");
                closeModal("contactModal")
            }
        });
    }


    return (
        <>
            <form onSubmit={submitContactForm} method="post">
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="">First Name*</label>
                            <input onChange={handleContactFormChange} value={contactForm.firstName || ""} name="firstName" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="">Last Name*</label>
                            <input onChange={handleContactFormChange} value={contactForm.lastName || ""} name="lastName" type="text" className="form-control" id="" placeholder="" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Email ID*</label>
                        <input onChange={handleContactFormChange} value={contactForm.email || ""} name="email" type="email" className="form-control" id="" placeholder="" />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Message*</label>
                        <textarea onChange={handleContactFormChange} value={contactForm.message || ""} name="message" className="form-control" id="" cols="30" placeholder="" rows="10"></textarea>
                    </div>
                </div>
                <div className="col-12">
                    <button type="submit"  className="blue-text-right">Mail Us</button>
                </div>
            </form>
        </>
    )
}

export default ContactFormComponent;