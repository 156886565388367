import React, { useState, useEffect } from "react";
import blogActions from "../../actions/homeActions";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { type } from "@testing-library/user-event/dist/type";
import { ShowImage } from "../../utils/helpers";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import Action from "../../actions/login.action";

const Header = (props) => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [homeData, setHomeData] = useState({});
  const [isHome, setIsHome] = useState(false);
  const [path, setPath] = useState("");
  const history = useNavigate();
  let location = useLocation();
  let pathname = location.pathname;
  pathname = location.pathname.substring(1);
  useEffect(() => {
    let path = [];
    let type = location.pathname;
    console.log(type, "asdfghjkl;sdfghjkl;");
    setPath(type);
    type = type.substring(1);
    if (!type.length > 0) {
      type = "home";
      setIsHome(true);
    }

    let payload = {
      type,
    };

    blogActions.getHomeData(payload, (err, res) => {
      if (err) {
      } else {
        setHomeData(res.data);
        console.log(res.data);
      }
    });
  }, []);

  const isActivePage = (pagePath) => {
    if (window.location.pathname === pagePath) {
      return true;
    }
    return false;
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };


  const userData = async () => {
    const data = await Action.getUserData((err, res) => {
      if (res?.statusCode == "200") {
        setImage(res.data.imageUrl);
      } else {
        console.log("errrrrr");
      }
    });
  };
  if (path == "/community-hub") {
    userData();
  }

  return (
    <>
      <nav className="navbar py-0">
        <div className="relate-app">
          <div className="light-nav position-fixed">
            <nav className="navbar navbar-expand-lg navbar-light w-100 py-0">
              <Link to="/" className="navbar-brand logo-part">
                <img src="/img/logo.png" alt="" />
              </Link>
              <button
                type="button"
                className="navbar-toggler logo-part-botton"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <img src="/img/menu-web.png" alt="" />
              </button>
              <div
                className="collapse navbar-collapse center-part"
                id="navbarCollapse"
              >
                <div className="navbar-nav nav-links-menu comunity-navs light-nav-2">
                  {isActivePage("/community-hub") ||
                    isActivePage("/community-hub") ? (
                    <>
                      <HashLink
                        className={`${location.pathname === "/#about" ? "active" : ""
                          }`}
                        smooth
                        to="/#about"
                      >
                        ABOUT IC.
                      </HashLink>

                      {/* < Link to="/joinUs"
          className={`${location.pathname === "/joinUs" ? "active" : ""
                        }`}
                      >
                      Join us
                    </Link>  */}
                      {localStorage.getItem("token") ? (
                        <>
                          <Link
                            to="/community-hub"
                            className={`${location.pathname === "/community-hub"
                                ? "active"
                                : ""
                              }`}
                          >
                            COMMUNITY Hub
                          </Link>
                          <div className="hide-show user-profilebar">
                            <div
                              className="profile-image"
                              style={{
                                backgroundImage: `url("${ShowImage(image)}")`,
                              }}
                            ></div>
                            <NavDropdown id="basic-nav-dropdown">
                              <Link to="/profile">Profile</Link>
                              <Link to="/" onClick={logout}>
                                Logout
                              </Link>
                            </NavDropdown>{" "}
                          </div>
                          <div className="mobilenav-community">

                            <Link className='mobview' to="/profile"><div
                              className="profile-image"
                              style={{
                                backgroundImage: `url("${ShowImage(image)}")`,
                              }}
                            ></div> Profile
                            </Link>
                            <Link to="/" onClick={logout}>
                              Logout
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <Link
                            to="/community-hub"
                            className={`${location.pathname === "/community-hub"
                                ? "active"
                                : ""
                              }`}
                          >
                            COMMUNITY Hub
                          </Link>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <HashLink
                        className={`${location.pathname === "/#about" ? "active" : ""
                          }`}
                        smooth
                        to="/#about"
                      >
                        ABOUT IC.
                      </HashLink>

                      {/* {

                        isActivePage("/community-hub") ||
                        isActivePage("/community-hub")?


                        < Link to="/joinUs"
          className={`${location.pathname === "/joinUs" ? "active" : ""
                        }`}
                      >
                      Join us
                    </Link> 
                     :""
                  } */}
                  <Link
                    className={`${location.pathname === "/blog" ? "active" : ""
                      }`}
                    to="/blog"
                  >
                    BLOGS
                  </Link>
                  <Link
                    className={`${location.pathname === "/event" ? "active" : ""
                      }`}
                    to="/event"
                  >
                    Events
                  </Link>
                  <Link
                    to="/connect"
                    className={`${location.pathname === "/connect" ? "active" : ""
                      }`}
                  >
                    CONTACT
                  </Link>

                  {localStorage.getItem("token") ? (
                    <>
                      <Link
                        to="/community-hub"
                        className={`${location.pathname === "/community-hub"
                            ? "active"
                            : ""
                          }`}
                      >
                        COMMUNITY Hub
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/community-hub"
                        className={`${location.pathname === "/community-hub"
                            ? "active"
                            : ""
                          }`}
                      >
                        COMMUNITY Hub
                      </Link>
                    </>
                  )}
                </>
                  )}
              </div>
          </div>
          {/* {localStorage.getItem("token") ? 
                       <div className="hide-show user-profilebar">
                       <img src="img/rakesh.jpg" alt="" />
                       <NavDropdown  id="basic-nav-dropdown">
                          
  
             <Button>   <Link to="/profile" >View Profile</Link></Button>
                <Button >
  
                  <Link to="/" onClick={logout} >LOG OUT</Link>
                </Button>
                
              </NavDropdown> </div> : ''}
                    */}
      </nav>
    </div >
        </div >
      </nav >
    </>
  );
};

export default Header;
