import React, { Component, useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { openModal, closeModal, extractNameValue, validateJOIProperty, hideLoader, showLoader, validateJOIFormField, showToastSuccess, showToastError } from "./../utils/helpers"

import homeActions from "../actions/homeActions";
import { PASSIONS, SERVICES } from "../utils/app.constants";

import moment from "moment";
import ShareTalentForm from "./contact.model/ShareTalent.modal";

import FieldError from "./FieldError";
import * as Joi from "joi-browser";

const ContactNow = (props) => {
    const [workSamples, satWorkSamples] = useState([])
    const [work, setWork] = useState([])
    const history = useNavigate();

    const [contactForm, setContactForm] = useState({
        firstName: '',
        companyName: '',
        email: '',
        message: '',
        field: '',
        contact: ''
    });

    const [isModalOpen, setIsModalOpen] = useState(true);

    const [contactErrors, setContactErrors] = useState({});
    const [talentErrors, setTalentErrors] = useState({});

    const contactSchema = {
        name: Joi.string().required().label("Name"),
        companyName: Joi.string().required().label("Company Name"),
        email: Joi.string().required().label("Email"),
        contact: Joi.string().required().label("Contact No."),
        field: Joi.string().required().label("Field"),
        message: Joi.string().required().label("Message"),
    };

    const shareTalentSchema = {
        name: Joi.string().required().label("Name"),
        address: Joi.string().required().label("Address"),
        passion: Joi.string().required().label("Passion"),
        subPassion: Joi.string().required().label("Sub Passion"),
        service: Joi.string().required().label("Service"),
    };
 
    useEffect(() => {
        setIsModalOpen(true);
    }, [props]);

    const handleImage = (e) => {
        satWorkSamples(e.target.files);
    }

    const handleContactFormChange = (event) => {
        const { name, value } = extractNameValue(event.target);
        contactForm[name] = value;
        setContactForm({ ...contactForm });

        let errorData = { ...contactErrors };
        const errorMessage = validateJOIProperty(contactSchema, event);
        if (errorMessage) {
            errorData[name] = errorMessage;
        } else {
            delete errorData[name];
        }
        setContactErrors(errorData);
    }

    const openModal = () => {
        console.log("jkbjhb")
        window.jQuery("#ModalForTalent").modal("show");
    }

    const submitContactForm = (event) => {
        event.preventDefault();
        let errors = validateJOIFormField(contactForm, contactSchema);
        if (errors == null) {
            showLoader();
            setContactForm({ ...contactForm });
            homeActions.contactPeopleRequest(props.user?._id, contactForm, (err, res) => {
                hideLoader();
                if (err) {
                    showToastError(err.message);
                } else {
                    setIsModalOpen(false);
                    setContactForm({
                        firstName: '',
                        companyName: '',
                        email: '',
                        contact: '',
                        message: '',
                        field: ''
                    });
                    showToastSuccess("Contact request has been sent.");
                }
            });
        } else {
            setContactErrors(errors);
        }
    }


    let [individualForm, setIndividualForm] = useState({
        name: '',
        address: '',
        passion: '',
        subPassion: '',
        service: '',
        image: null
    });

    let [selectedPassion, setSelectedPassion] = useState(null);

    let [subPassions, setSubPassions] = useState([]);

    const updateModalValues = (name, value) => {
        individualForm[name] = value;
        setIndividualForm({ ...individualForm });
        console.log(individualForm);
    }

    const handleIndividualFormChange = (event) => {
        console.log(event);
        const { name, value } = extractNameValue(event.target);
        updateModalValues(name, value);

        let errorData = { ...talentErrors };
        const errorMessage = validateJOIProperty(shareTalentSchema, event);
        if (errorMessage) {
            errorData[name] = errorMessage;
        } else {
            delete errorData[name];
        }
        setTalentErrors(errorData);
    }

    const submitIndividualForm = (event) => {
        event.preventDefault();

        let errors = validateJOIFormField(individualForm, shareTalentSchema);
        if (errors == null) {
            showLoader();


            setIndividualForm({ ...individualForm });

            var formData = new FormData();
            formData.append('name', individualForm.name);
            formData.append('address', individualForm.address);
            formData.append('passion', individualForm.passion);
            formData.append('subPassion', individualForm.subPassion);
            formData.append('service', individualForm.service);

            if (workSamples.length > 0) {

                for (const key of Object.keys(workSamples)) {
                    formData.append('images', workSamples[key])
                }

                homeActions.shareTalent(
                    formData,
                    (err, res) => {
                        hideLoader();
                        if (err) {
                            console.log(err);
                            showToastError(err.message);
                        } else {
                            setIndividualForm({
                                name: '',
                                address: '',
                                passion: '',
                                subPassion: '',
                                service: ''
                            })
                            closeModal("contactModal")
                            setIsModalOpen(false);
                            showToastSuccess("Thank you for sharing your talent, we will get back to you.");
                        }
                    });

            } else {
                showToastError("Work Samples are required");
            }

        } else {
            setTalentErrors(errors);
        }
    }

    const handlePassionChange = (event) => {
        selectedPassion = event.target.value;
        setSelectedPassion(selectedPassion);
        updateModalValues('passion', selectedPassion);
        updateModalValues('subPassion', '');
        let subPassionItemsData = [];
        for (let passion of PASSIONS) {
            if (passion.title == selectedPassion) {
                subPassionItemsData = passion.subItems;
            }
        }
        setSubPassions(subPassionItemsData);
    }

    return (
        <>
            {!isModalOpen ? <form className="joiningForm theme-form-group-fields" onSubmit={submitIndividualForm} method="post">
                <div className="thanks-box">
                    <img src="/img/logo.png" />
                    <p>Thank you for your interest in working with a member of our community talent pool.</p>
                    <h5>We will get back to you as soon as possible.</h5>
                    <p>Share your talent with the world. Submit your profile and work.</p>
                </div>
                <div className="form-group">
                    <label htmlFor="">Name*</label>
                    <input onChange={handleIndividualFormChange} value={individualForm.name || ""} name="name" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                    <FieldError error={talentErrors.name} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Address</label>
                    <input onChange={handleIndividualFormChange} value={individualForm.address || ""} name="address" type="" className="form-control" id="" placeholder="" />
                    <FieldError error={talentErrors.address} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Passion/Expertise</label>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <select onChange={handlePassionChange} name="passion" value={individualForm.passion || ""} className="" id="">
                                <option value="">--Select--</option>
                                {
                                    PASSIONS.map((passionItem, i) => {
                                        return (
                                            <>
                                                <option value={passionItem.title}>{passionItem.title}</option>
                                            </>
                                        )
                                    })
                                }
                                <option value="others">Others</option>
                            </select>
                            <FieldError error={talentErrors.passion} />
                        </div>
                        <div className="col-md-6 col-12">
                            <select onChange={handleIndividualFormChange} name="subPassion" value={individualForm.subPassion || ""} className="" id="">
                                <option value="">--Select--</option>
                                {
                                    subPassions.map((subPassionItem, i) => {
                                        return (
                                            <>
                                                <option value={subPassionItem.title}>{subPassionItem.title}</option>
                                            </>
                                        )
                                    })
                                }
                                <option value="others">Others</option>
                            </select>
                            <FieldError error={talentErrors.subPassion} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="">Product/Services</label>
                    <select onChange={handleIndividualFormChange} value={individualForm.service || ""} name="service" className="" id="">
                        <option value="">--Select--</option>
                        {
                            SERVICES.map((serviceItem, i) => {
                                return (
                                    <>
                                        <option value={serviceItem.title}>{serviceItem.title}</option>
                                    </>
                                )
                            })
                        }
                        <option value="other">Other</option>
                    </select>
                    <FieldError error={talentErrors.service} />
                </div>

                <div className="form-group">
                    <label htmlFor="">Work Samples</label>
                    <input type="file" name="images[]" multiple onChange={(e) => handleImage(e)} />
                    <FieldError error={talentErrors.images} />
                </div>

                <div className="form-group">
                    <button type="submit"  className="blue-text-right">Mail Us</button>
                </div>
            </form>
                :

                <form onSubmit={submitContactForm} method="post" className="theme-form-group-fields">
                    <h4 className="title">Contact Now</h4>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="">Name*</label>
                                <input onChange={handleContactFormChange} value={contactForm.name || ""} name="name" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                                <FieldError error={contactErrors.name} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="">Name Of the Company*</label>
                                <input onChange={handleContactFormChange} value={contactForm.companyName || ""} name="companyName" type="text" className="form-control" id="" placeholder="" />
                                <FieldError error={contactErrors.companyName} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="">Email ID*</label>
                                <input onChange={handleContactFormChange} value={contactForm.email || ""} name="email" type="email" className="form-control" id="" placeholder="" />
                                <FieldError error={contactErrors.email} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="">Contact Number*</label>
                                <input onChange={handleContactFormChange} value={contactForm.contact || ""} name="contact" type="text" className="form-control" id="" placeholder="" />
                                <FieldError error={contactErrors.contact} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="">Field of work*</label>
                            <select onChange={handleContactFormChange} value={contactForm.field || ""} name="field" type="number" className="form-control" id="" placeholder=""  >
                                <option value="">--Select--</option>
                                {
                                    SERVICES.map((serviceItem, i) => {
                                        return (
                                            <>
                                                <option key={i} value={serviceItem.title}>{serviceItem.title}</option>
                                            </>
                                        )
                                    })
                                }
                                <option value="other">Other</option>
                            </select>
                            <FieldError error={contactErrors.field} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="">How can we help you*</label>
                            <textarea onChange={handleContactFormChange} value={contactForm.message || ""} name="message" className="form-control" id="" cols="30" placeholder="" rows="5" ></textarea>
                            <FieldError error={contactErrors.message} />
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="blue-text-right">Mail Us</button>
                    </div>
                </form>


            }
        </>
    )
}

export default ContactNow;