import React, { Component, useEffect, useState } from "react"
import Header from "../TopHeader"
import Abc from "../footer"
import homeActions from "../../actions/homeActions"
import ContactNow from "../contactNow"
import { openModal, closeModal, extractNameValue } from "../../utils/helpers"



const ContactModel = (props) => {

  return (
    <>
      <div className="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="portfoloModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body modal-extra-body theme-form-section">
              <ContactNow user={props?.user}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactModel
