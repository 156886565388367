import React, { Component, useState, useEffect } from "react"
// import homeActions from "../../actions/homeActions";
// import loginActions from "../../actions/login.action";
// import { openModal, closeModal, extractNameValue, withAppUrl } from "./../../utils/helpers"
// import Header from "../TopHeader"
// import Abc from "../footer"

import homeActions from "../actions/homeActions";
import loginActions from "../actions/login.action";
import { withAppUrl, loadLazyLoaded, ShowImage } from "../utils/helpers";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import Header from "./TopHeader";
import Abc from "./footer";
import blogActions from '../actions/homeActions'
import ReactPaginate from "react-paginate";


const Event = () => {
    const history = useNavigate();
const [classs, setClass] =useState('sec-whatwedo')

    const [Expert, setExpert] = useState([]);
    const [blogList, setBlog] = useState([]);
    const [ourWorkList, setOurWorkList] = useState([]);
    const [resources, setResources] = useState([])
    const [pages, setPages] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);
  
    const [homeData, setHomeData] = useState({});
    const [isHome, setIsHome] = useState(false);
    const [ShowTopBtn, setShowTopBtn] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({
        docs: [],
        hasNextPage: false,
        hasPrevPage: false,
        limit: 0,
        offset: 0,
        page: 0,
        pagingCounter: 0,
        totalDocs: 0,
        totalPages: 0,
    });

    let location = useLocation();
    let pathname = location.pathname;


    const handlePageClick = (data) => {
        let current = data.selected + 1;
        console.log(current, "currentpage");
        setCurrentPage(current);

    };
    // useEffect(() => {
    //     let type = "home";
    //     type = type.substring(0)
    //     if (!type.length > 0) {
    //         type = "home"
    //         setIsHome(true)
    //     }
    //     let payload = {
    //         type
    //     };
    //     console.log(type)
      
    //     loadLazyLoaded();
    // }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {

            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, [currentPage]);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    const isActivePage = (pagePath) => {
        if (window.location.pathname === pagePath) {
            return true;
        }
        return false;
    }


    useEffect(() => {
        let payload = {};
        homeActions.getExpert((err, res) => {
            if (err) {

            } else {
                setExpert(res.data);

            }
        })
    }, [])
    useEffect(() => {
        let payload = {};
        homeActions.getBlog(payload, (err, res) => {
            if (err) {

            } else {
                setBlog(res.data.blogList);
            }
        })
        homeActions.getOurWork((err, res) => {
            if (err) {

            } else {
                setOurWorkList(res.data);

            }
        })
        setIsLoading(true);
        payload.currentPage = currentPage;
        payload.limit = 8;
        loginActions.getEvents(payload,(err, res) => {
            setIsLoading(false);

            if (err) {

            } else {
                setPaginationData(res.data.blogList);

            }
        })

    }, [currentPage]);

    const handleResources = (e) => {
        history(`/event/details/${e}`)
    }
    useEffect(() => {

    {localStorage.getItem("token") ? 
    setClass('sec-whatwedo afterlogin') :
    setClass('sec-whatwedo')
     }
    },[])



    return (<>

        <Header></Header>
        <section >
            <div className="container">
               {!isLoading ? <>


        <section className={`${classs}`}>
            <div className="container">
                        <div className="section-head work">
                        
                            <h2>Events</h2>
                        </div>
            </div>
            <div className="top-wapper rescorse-block">
                <div className="container">
                    <div className="row">
                        {paginationData?.docs?.map((item, index) =>

                            <div key={index} className="col-lg-3 col-md-6 col-sm-12" >
                                <a href={undefined} className="resource-box Rsource-bar" onClick={(e) => handleResources(item?._id)}>
                                    <div className="recource-img">
                                        <img src={`${ShowImage(item?.imageUrl)}`} alt="img" />
                                    </div>
                                    <div className="resource-content" >
                                        <h4>{item?.EventTitle}</h4>
                                       
                                        {item?.Description.length > 40 ?
                                            (
                                                <div className="text-black">
                                                    {`${item?.Description.substring(0, 40)}... Read more`}
                                                </div>
                                            ) :
                                            <p>{item?.Description}</p>
                                        }

                                    </div>
                                </a>
                            </div>)}
                    </div>
                </div>
            </div>
        </section>

        </> : <>
                  <p className="text-center d-block">Loading <i className="fa fa-spin fa-spinner"></i></p>
               </>
               }

            </div>
         </section>
         <div className="row">
            <div className="col-12">
                <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={paginationData?.totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={100}
                    onPageChange={handlePageClick}
                    containerClassName={
                        "pagination justify-content-center"
                    }
                    forcePage={paginationData?.page - 1}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div>
        </div>

        <Abc></Abc>
    </>)
}

export default Event;