import React, { Component, useEffect, useState } from "react";
import Header from "../TopHeader"
import Abc from "../footer"
import blogActions from "../../actions/homeActions";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import { ShowImage } from "../../utils/helpers";

const Blog = (props) => {
   const history = useNavigate();

   const [blogList, setBlog] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [sizePerPage, setSizePerPage] = useState(6);
   const [pages, setPages] = useState(6);
   const [dummy, setDummy] = useState(0);

   useEffect(() => {
      let payload = {};
      blogActions.getBlog(payload, (err, res) => {
         if (err) {

         } else {
            setBlog(res.data.blogList);

         }
      })
   }, [])
   useEffect(() => {

      getBlog();

   }, [currentPage, dummy])
   const getBlog = async () => {
      let dataToSend = {
         limit: sizePerPage,
         page: currentPage,
      }
      blogActions.getBlog(dataToSend, (err, res) => {
         if (err) {
            console.log(err, " appointments error")
         } else {
            setBlog(res.data.blogList);

            setPages(
               parseInt(res.data.count % sizePerPage) == 0
                  ? parseInt(res.data.count / sizePerPage)
                  : parseInt(res.data.count / sizePerPage + 1)
            );

            setDummy(0);
            console.log(res.data, " Apoointments are");
         }
      });

   };
   const handlePageClick = (data) => {
      let current = data.selected + 1;
      console.log(current, "currentpage");
      setCurrentPage(current);
   };
   return (
      <>

         <Header></Header>

         <section className="space-wapper down-space newsbar">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="section-head latest-Articles what-long">
                        <span>News and blogs</span>
                        <h2>Read all our  <span className="diff">latest Insights</span></h2>
                     </div>
                  </div>
               </div>
            </div>
            <br />
            <br />
            <div className="top-wapper">
               <div className="container">
                  <div className="row">
                     {blogList.length > 0 && blogList ? blogList.map((ele) => {
                        let ArticleBody = ele.ArticleBody.substring(0, 100)
                        return (
                           <>

                              <div className="col-lg-4 col-md-4 blogbar">
                                 <div className="our-content">
                                    <img src={ShowImage(ele.imageUrl)} alt="" />
                                    <h3>{ele.ArticleTitle}</h3>
                                    <h5>{moment(ele.createAt).format("YYYY-MM-DD")} / <span>by {ele.publishedBy}</span></h5>
                                    <p>{ArticleBody}</p>
                                    <a href={undefined} onClick={(e) => {
                                       e.preventDefault();
                                       history(`/Blogpage/${ele._id}`);
                                    }}>Read More <img src="img/Arrow-next.png" alt="" /></a>
                                 </div>
                              </div>

                           </>
                        )
                     }) : ""}
                  </div>
                  <br />
                  <br />
                  <div className="row">
                     <div className="col-12">
                        <ReactPaginate
                           previousLabel={"previous"}
                           nextLabel={"next"}
                           breakLabel={"..."}
                           pageCount={pages}
                           marginPagesDisplayed={1}
                           pageRangeDisplayed={100}
                           onPageChange={handlePageClick}
                           containerClassName={
                              "pagination justify-content-center"
                           }
                           forcePage={currentPage - 1}
                           pageClassName={"page-item"}
                           pageLinkClassName={"page-link"}
                           previousClassName={"page-item"}
                           previousLinkClassName={"page-link"}
                           nextClassName={"page-item"}
                           nextLinkClassName={"page-link"}
                           breakClassName={"page-item"}
                           breakLinkClassName={"page-link"}
                           activeClassName={"active"}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Abc></Abc>
      </>
   );

}

export default Blog;
