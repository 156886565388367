import React, { Component, useEffect, useState } from "react";

import moment from "moment";
import Header from "./TopHeader";
import Abc from "./footer";
import { useNavigate, useParams } from "react-router-dom";
import loginAction from "./../actions/login.action";
import {
  openModal,
  closeModal,
  extractNameValue,
  withAppUrl,
  ShowImage,
} from "./../utils/helpers";
import ContactModel from "./contact.model/contact.modal";
import ShareTalentForm from "./contact.model/ShareTalent.modal";
import ReactPaginate from "react-paginate";

const EventDetails = (props) => {
  const [users, setUsers] = useState("");
  const navigate = useNavigate();
  const [pages, setPages] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [event, setEvent] = useState({});
  const [media, setMedia] = useState([]);

  const { id } = useParams();
  console.log(id, "dd");
  const [portfolio, setPortfolio] = useState([]);
  const [user, setUser] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const handlePageClick = (data) => {
    let current = data.selected + 1;
    console.log(current, "currentpage");
    setCurrentPage(current);
  };

  useEffect(() => {
    setIsLoading(true);
    console.log("id", id);
    let payload = {};
    payload.id = id;
    payload.currentPage = currentPage;
    payload.limit = 4;

    loginAction.getEvent(payload, (err, res) => {
      setIsLoading(false);
      if (err) {
      } else {
        console.log("data", res.data);
        setEvent(res.data);
        setMedia(res.data.Media);
      }
    });

    //   loginAction.getUser(id, (err, res) => {
    //      setIsLoading(false);
    //      if (err) {

    //      } else {
    //         setUser(res.data);

    //      }
    //   }
    //   )
  }, [currentPage]);

  return (
    <>
      <Header></Header>

      <section className="space-wapper down-space people-block peopledetail-block">
        <div className="container">
          {!isLoading ? (
            <>
              <h4 className="title">{event?.EventTitle}</h4>

              <div className="people-bar peopledetail-bar">
                {/* <img src={`${ShowImage(event?.imageUrl)}`} alt="img" /> */}
                <div className="people-user">
                  <ul>
                    <li>
                      {/* <a href={undefined}>{event?.EventTitle}</a> */}
                      <h6>
                        {moment(event?.Date).format("DD-MM-YYYY")} 
                      </h6>
                    </li>
                  </ul>
                  <p>{event?.Description}</p>
                </div>
              </div>
              <h4 className="title portfoliobar-title">{media.length > 0 ? 'Media' : 'Media Not Available'}</h4>

              <div className="row">
                {media?.map((item) => (
                  <div className="col-md-6 col-lg-3">
                    <a href={undefined} className="portfolio-listing clickable">
                      <div className="portfolio-img">
                        {item.slice(-3).slice(0, 2) == "mp" ? (
                          <>
                            <video controls autoPlay muted>
                              <source
                                src={`${withAppUrl(item)}`}
                                type="video/mp4"
                              />
                            </video>
                          </>
                        ) : (
                          <>
                            <img src={`${withAppUrl(item)}`} alt="img" />
                          </>
                        )}
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <p className="text-center d-block">
                Loading <i className="fa fa-spin fa-spinner"></i>
              </p>
            </>
          )}
        </div>
      </section>
      {/* <div className="row">
        <div className="col-12">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={pages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={100}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            forcePage={currentPage - 1}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div> */}

      <Abc></Abc>
    </>
  );
};

export default EventDetails;
