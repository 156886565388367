import React, { Component, useState, useEffect } from "react"
import Header from "../TopHeader"
import Abc from "../footer"

import ContactFormComponent from "../ContactFormComponent"
import IndividualComponent from "../IndividualComponent"
import OrganizationComponent from "../OrganizationComponent"

import { Link, useLocation, useNavigate } from "react-router-dom";
import { openModal, closeModal, extractNameValue, withAppUrl, ShowImage } from "./../../utils/helpers"

import homeActions from "../../actions/homeActions";
import loginActions from "../../actions/login.action";

import moment from "moment";
const EditFormPost = (props) => {
   const history = useNavigate();


   const [Expert, setExpert] = useState([]);
   const [blogList, setBlog] = useState([]);
   const [ourWorkList, setOurWorkList] = useState([]);
   const [resources, setResources] = useState([])


   useEffect(() => {
      let payload = {};
      homeActions.getExpert((err, res) => {
         if (err) {

         } else {
            setExpert(res.data);

         }
      })
   }, [])
   useEffect(() => {
      let payload = {};
      homeActions.getBlog(payload, (err, res) => {
         if (err) {

         } else {
            setBlog(res.data.blogList);
         }
      })
      homeActions.getOurWork((err, res) => {
         if (err) {

         } else {
            setOurWorkList(res.data);

         }
      })
      loginActions.getResources((err, res) => {
         if (err) {

         } else {
            setResources(res.data);

         }
      })

   }, []);

   const handleResources = (e) => {
      history(`/people/${e}`)
   }

   return (
      <>

         <Header></Header>
         <section className="space-wapper" id="about">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="section-head new-head">
                        <span>about us</span>
                        <h2>For the <span className="diff">Community</span>, By the <span className="diff">Community</span>.</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-12">
                     <div className="p-content new-p-content">
                        <p>Infinite Communities is a strategic and creative consultancy powered by Shared Value Creators dedicated to providing impactful and innovative solutions that generate mutual benefits for businesses and communities.</p>
                     </div>
                  </div>
               </div>
            </div> 
         </section>


         <section className="space-wapper ">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 community-full-box">
                     <div className="aside-head">
                        <img loading="lazy" src="img/icon21.png" alt="" />

                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 community-full-box">
                     <div className="aside-head">
                        <img loading="lazy" src="img/icon22.png" alt="" />

                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 community-full-box">
                     <div className="aside-head">
                        <img loading="lazy" src="img/icon23.png" alt="" />

                     </div>
                  </div>

               </div>
            </div>
         </section>
         <section className="space-wapper">
            <div className="container-fulid">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="section-head what-long new-head">
                        <span>what we do</span>
                        <h2>By the<span className="diff"> Community</span>, For the <span className="diff">Community</span>.</h2>
                     </div>
                  </div>
               </div>
               
            </div>
            <div className="videoblock">
               <div className="p-content">
                  <div className="videocontainer">  
                     <video autoPlay muted loop id="myVideo" preload="none" poster={withAppUrl('public/thumbnails/second-min.webp')}>
                        <source src="/img/sceond.mp4" type="video/mp4" />
                        Your browser does not support HTML5 video.
                     </video>
                  </div>
               </div>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="p-content">
                        <div className="by-title">
                           {/* <p>To achieve sustainable impact, we believe it is not enough for any initiative to achieve scale.
                              It needs to accelerate. And only communities that come together from an infinite pool of talent
                              and inspiration can achieve that.
                           </p>
                           <p>Infinite Communities (IC) harnesses the collective strengths of members and their networks to
                              deliver solutions that create shared values for your brands and businesses.</p> */}
                           <p>Our purpose is to constantly find ways in which both companies and
communities can prosper, sustainably and responsibly. To achieve this, we
put Creating Shared Value at the heart of everything we do - a framework
for creating economic value for our clients while addressing the needs of
the communities we serve</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="space-wapper strategy-boxes mb-5">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                     <div className="list-item stretegy-box">
                        <div className="stretegy-img">
                           <img loading="lazy" src="/img/stretegy.png" alt="Strategy" />
                        </div>
                        <div className="contentbar">
                           <h2>IC Strategy</h2>
                           <ul>
                           <li><a href={undefined}>Social Good Marketing</a></li>
                              <li><a href={undefined}>Corporate Social Responsibility</a></li>
                              <li><a href={undefined}>Purpose-Driven Branding</a></li>
                              {/* <li><a href={undefined}>Community Leadership</a></li>
                              <li><a href={undefined}>Brand Purpose</a></li>
                              <li><a href={undefined}>Cause Campaign</a></li> */}

                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                     <div className="list-item stretegy-box">
                        <div className="stretegy-img">
                           <img loading="lazy" src="img/ic-digital.jpg" alt="img" />
                        </div>
                        <div className="contentbar">
                           <h2>IC Community Events and Activations</h2>
                           <ul>
                              <li><a href={undefined}>Program Design</a></li>
                              <li><a href={undefined}>Event Management</a></li>
                              <li><a href={undefined}>Community Outreach</a></li>
                              <li><a href={undefined}>Workshops and Seminars</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                     <div className="list-item stretegy-box">
                        <div className="stretegy-img">
                           <img loading="lazy" src="img/events.png" alt="img" />
                        </div>
                        <div className="contentbar">
                           <h2>IC Multimedia</h2>
                           <ul>
                           <li><a href={undefined}>Content Creation</a></li>
                              <li><a href={undefined}>Video Production</a></li>
                              <li><a href={undefined}>Corporate | Feature Films</a></li>
                              <li><a href={undefined}>Documentary</a></li>
                              <li><a href={undefined}>Animation</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                     <div className="list-item stretegy-box">
                        <div className="stretegy-img">
                           <img loading="lazy" src="img/video.png" alt="img" />
                           <div className="logo-overlay">
                           <a className="seraphic-logo" href="http://www.seraphic.io/" target="_blank"><img  src="https://seraphic.io/wp-content/uploads/2023/09/seraphic-logo.png"/></a>
                           </div>
                        </div>
                        <div className="contentbar">
                           <h2>IC Digital in partnership with IO</h2>
                           <ul>
                              <li><a href={undefined}>Mobile App Development</a></li>
                              <li><a href={undefined}>Web Development</a></li>
                              <li><a href={undefined}>UI/UX Designs</a></li>
                              <li><a href={undefined}>Web Designs</a></li>
                              <li><a href={undefined}>Custom CRM Solutions</a></li>
                              <li><a href={undefined}>ERP Solutions</a></li>
                           </ul>
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="space-wapper bg-back">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 full-row">
                     <div className="section-head wid-long experts-title">
                        <span>Experts</span>
                        <h2>Your <span className="diff">friends</span> behind IC </h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  {Expert.length > 0 ? Expert.map((ele, index) => {
                     return (
                        <div key={index} className="col-xl-3 col-lg-6 col-md-6 portfoliobar">
                           <div className="profile-client">
                              <div className="box-client">
                                 <img loading="lazy" src={ShowImage(ele.imageUrl)} alt="" />
                                 <div className="overlay" onClick={(e => {
                                    e.preventDefault();
                                    window.location.href = ele.profileUrl;
                                 })}>
                                    <div className="text">View Profile </div>
                                 </div>
                              </div>
                           </div>
                           <div className="info-client">
                              <h4>{ele.name}</h4>
                              <a href={ele.profileUrl}>{ele.position}</a>
                              <div className="expertBio">{ele?.bio}</div>
                           </div>
                        </div>
                     )
                  }) : ""}

               </div>
            </div>
         </section>
         {/* <section className="space-wapper">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="section-head us">
                        <h2>Join Us</h2>
                     </div>
                     <div className="p-content new-p-content">
                        <div className="by-title">
                           <h5>Want to share your advocacy with the world?</h5>
                           <p>Connect with us and join our growing community of strategists, creative minds, content creators, social and environmental program designers, and other advocates of social good. Let us work together on sustainable solutions that make the world a better place.</p>
                           <p>By becoming a part of the Infinite Communities, you can have access to the IC global network and exclusive access to the IC Community Hub and resources. IC members can benefit from the community platform for collaboration and knowledge exchange. </p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row formsgroup">
                  <div className="col-lg-6 col-md-6">
                     <div className="Joining-text ">
                        <h4>Joining as an <span className="diff">organization</span></h4>
                     </div>

                     <div className="from-section">
                        <OrganizationComponent></OrganizationComponent>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                     <div className="Joining-text ">
                        <h4>Joining as an <span className="diff">Individual</span></h4>
                     </div>

                     <div className="from-section">
                        <IndividualComponent></IndividualComponent>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}

         <section className="space-wapper">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="section-head latest-Articles what-long">
                        <span>News and blogs</span>
                        <h2>Read All Our   <span className="diff">Latest Updates and Insights</span></h2>
                     </div>
                  </div>
               </div>
            </div>
            <br />
            <br />
            <div className="top-wapper">
               <div className="container">
                  <div className="row">
                     {blogList.length > 0 ? blogList.map((ele, index) => {
                        let ArticleBody = ele.ArticleBody.substring(0, 100)
                        return (
                           <>

                              <div key={index} className="col-lg-4 col-md-4 blogbar">
                                 <div className="our-content">
                                    <img loading="lazy" src={ShowImage(ele.imageUrl)} alt="" />
                                    <h3>{ele.ArticleTitle}</h3>
                                    {/* <h5>{moment(ele.createAt).format("YYYY-MM-DD")} / <span>by {ele.publishedBy}</span></h5>
                                    <p>{ArticleBody}</p> */}
                                    <a href={undefined}>Read More <img src="img/Arrow-next.png" alt="" /></a>
                                 </div>
                              </div>

                           </>
                        )
                     }) : ""}
                  </div>

                  <br />
                  <br />
                  <div className="row">
                     <div className="col-12">
                        <button onClick={(e) => {
                           e.preventDefault();
                           history("./blog");
                        }} className="blue-text">View More</button>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="space-wapper pb-5 contactblock">
            <div className="container">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="say-content">
                        <span>Contact us</span>
                        <h2>Say <span className="diff">Hello</span></h2>
                        <br />
                        <br />
                        <br />
                        <p>For any questions, assistance, or feedback</p>
                        <p>email us at <b><a href="mailto:Ops@infinitecommunities.com">Ops@infinitecommunities.com</a></b> or fill-up the form.</p>
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="info-section">
                        <ContactFormComponent></ContactFormComponent>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Abc></Abc>
      </>
   )
}

export default EditFormPost

{
   /* const [selectedOption, setSelectedOption] = useState(options[0].value);
   return (
       <select
         value={selectedOption}
         onChange={e => setSelectedOption(e.target.value)}>
         {options.map(o => (
           <option key={o.value} value={o.value}>{o.label}</option>
         ))}
       </select> */
}
